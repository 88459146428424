/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The most secure building is the one with no windows or doors. But, while that building may be very secure, it's probably not helpful unless the point is to house something until the end of time."), "\n", React.createElement(_components.p, null, "Security is a big part of doing SaaS integrations well. And it takes work. The challenge of B2B SaaS integrations is that they come with security vulnerabilities baked in. After all, to pass data from your SaaS app to your customers' SaaS apps, we need to have at least one window or door, as it were, in each system."), "\n", React.createElement(_components.h2, {
    id: "why-integration-security-is-important",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-integration-security-is-important",
    "aria-label": "why integration security is important permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why integration security is important"), "\n", React.createElement(_components.p, null, "Data drives most businesses and is a big part of what gives one company a competitive advantage over another. And as an increasing number of companies integrate their SaaS apps, securing that data becomes essential to protecting those companies' present and future operations."), "\n", React.createElement(_components.p, null, "A single data breach can have a massive negative impact on a company. IBM notes that ", React.createElement(_components.a, {
    href: "https://www.ibm.com/reports/data-breach",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "the average cost of a single data breach today in the United States is $9.44 million"), ". And that doesn't factor in the long-tail costs to brand and reputation."), "\n", React.createElement(_components.p, null, "In addition to the direct and reputation costs of a data breach, there are compliance costs. Having and reporting a data breach can open your company to specific liabilities. Based on where the breach occurred and how serious the breach was, your company may be the subject of investigations under ", React.createElement(_components.a, {
    href: "https://gdpr.eu/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "GDPR"), ", ", React.createElement(_components.a, {
    href: "https://cppa.ca.gov/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "CCPA"), ", or another privacy or security regulation."), "\n", React.createElement(_components.h2, {
    id: "integration-security-doesnt-just-happen",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#integration-security-doesnt-just-happen",
    "aria-label": "integration security doesnt just happen permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Integration security doesn't just happen"), "\n", React.createElement(_components.p, null, "Integration security isn't just important for your company; it is critical. And it's vital for every one of your customers for whom you are building B2B SaaS integrations. Based on what we've learned in assisting our customers with their integration security concerns, here are six items for your integration security checklist as you plan, build, or update your B2B SaaS integrations and integration ecosystem."), "\n", React.createElement(_components.h3, {
    id: "1-start-with-good-authentication",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#1-start-with-good-authentication",
    "aria-label": "1 start with good authentication permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "1. Start with good authentication"), "\n", React.createElement(_components.p, null, "Authentication (", React.createElement(_components.a, {
    href: "../making-best-auth-decisions-saas-integrations/"
  }, "auth"), ") is the starting point for integration security. As a rule, because there are always better options, you'll want to avoid basic auth for any B2B SaaS integrations."), "\n", React.createElement(_components.p, null, "At a minimum, you'll want to use API keys. When doing so, you'll want to set up unique API keys (and the corresponding scope) for each company or user accessing your SaaS integration. Never use a single API key and scope for multiple companies or end-users. Doing so increases the probability that someone will inadvertently gain access to data that is not theirs or that the credentials will be abused in ignorance or on purpose."), "\n", React.createElement(_components.p, null, "But the better approach would be to use OAuth 2.0. In this scenario, each company that connects to your app via an integration should use unique OAuth 2.0 credentials. And, if individual users at a company are connecting as themselves, they should also use unique OAuth 2.0 credentials."), "\n", React.createElement(_components.h3, {
    id: "2-validate-all-inputs",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2-validate-all-inputs",
    "aria-label": "2 validate all inputs permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "2. Validate all inputs"), "\n", React.createElement(_components.p, null, "API documentation should define acceptable inputs, but the integration itself should enforce those rules."), "\n", React.createElement(_components.p, null, "Credentials and other integration config options should have built-in data validation to ensure that any message metadata follows defined patterns. And the integration data itself should match specified schemas or patterns. Otherwise, the integration should fail to process the data."), "\n", React.createElement(_components.h3, {
    id: "3-keep-credentials-separate-from-source-code-and-data",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-keep-credentials-separate-from-source-code-and-data",
    "aria-label": "3 keep credentials separate from source code and data permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3. Keep credentials separate from source code and data"), "\n", React.createElement(_components.p, null, "When integrating multiple systems, you should have multiple sets of credentials or API keys to manage. You'll want to ensure that you use good vaults with proper access management to ensure that these remain secure. You should never include credentials in the source code for the integration."), "\n", React.createElement(_components.p, null, "At the same time, keep credentials separate from the integration data itself. The standard way to maintain this separation is to pass any encrypted keys via message headers rather than in the message body."), "\n", React.createElement(_components.h3, {
    id: "4-connect-with-trusted-systems-only",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#4-connect-with-trusted-systems-only",
    "aria-label": "4 connect with trusted systems only permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "4. Connect with trusted systems only"), "\n", React.createElement(_components.p, null, "You should only connect with trusted systems, and your customers should only connect with trusted systems. Your integration should access your customer's data through a verified app owned by your organization. That app should define the organization, including branding, privacy policies, support contacts, data usage contacts, and more."), "\n", React.createElement(_components.p, null, "Don't use unsigned or unverified third-party apps as part of your integration ecosystem. Customers connecting with your app should be assured that it's your app and not someone else's."), "\n", React.createElement(_components.p, null, "As part of this, your integrations shouldn't be requesting third-party services from a front-end app (UI). Instead, your integration should be run with a back-end app that connects systems via APIs or another means."), "\n", React.createElement(_components.h3, {
    id: "5-be-a-privilege-miser",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#5-be-a-privilege-miser",
    "aria-label": "5 be a privilege miser permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "5. Be a privilege miser"), "\n", React.createElement(_components.p, null, "Even when you've ensured that individual companies or users have unique credentials, you'll still want to ensure that you are providing the integration (and anything that's part of it, including the API) with only the needed access. We see privilege bloat on mobile apps where a notes app says that it \"needs\" access to absolutely everything else we've stored on the device."), "\n", React.createElement(_components.p, null, "In contrast, integrations should follow the principle of least privilege – giving the integration (and, by extension, an API or user) the minimum level of access necessary for the integration to run successfully. For example, if you have a Slack integration that posts messages to a single channel, it doesn't need access to read users' private messages. You can always add more privileges, but it's small comfort to restrict them after a security breach."), "\n", React.createElement(_components.h3, {
    id: "6-log-and-monitor-everything-except-the-customers-data",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#6-log-and-monitor-everything-except-the-customers-data",
    "aria-label": "6 log and monitor everything except the customers data permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "6. Log and monitor everything except the customer's data"), "\n", React.createElement(_components.p, null, "Many attempted data breaches fail. And that's a good thing. They fail for different reasons, but one common reason is that a system is being actively monitored, and unusual or unexpected access attempts are noticed before something bad happens."), "\n", React.createElement(_components.p, null, "Logging can help after the fact (whether or not a malicious access attempt is successful). However, you'll want to be sure that you are only logging metadata about integrations and their executions and not any customer data passing through the integrations."), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "Once you have your integrations set up and you've determined that everything is as secure as possible, you've made a good start. Regular audits (integration security assessments) are also a must, as are security best practices for new integration developers and DevOps to ensure that ignorance doesn't bypass your SaaS integration security safeguards."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/contact/"
  }, "Contact us"), " or ", React.createElement(_components.a, {
    href: "/request-a-demo/"
  }, "request a demo"), " if you want to see how Prismatic's ", React.createElement(_components.a, {
    href: "/resources/benefits-embedded-integration-platforms-for-saas-products/"
  }, "embedded integration platform"), " (embedded iPaaS) can help mitigate security risks for your B2B SaaS integrations."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
